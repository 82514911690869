import React from "react";
import MortgageCard from "./MortgageCard";
import RentalIncomeCard from "./RentalIncomeCard";
import RealizationCard from "./RealizationCard";
import ProfitabilityCard from "./ProfitabilityCard";
import "../App.css";
import QR from '../img/qr2.jpg';

function LeftColumn({ setMortgageData, setRentalData, setRealizationData, mortgageData, rentalData, realizationData }) {
  return (
    <div className="left-column">
            <div className="stepper-content">
          <div className="qrcode-block">
          <img src={QR} alt="QR Code" className="qrcode-image" />
            <div className="qrcode-text">
              <p>Перейти на канал Армена Никогосова</p>
              <button className="qrcode-button highlight-blue" onClick={() => window.open('https://t.me/armen_nikogosov', '_blank')}><strong>Перейти</strong></button>
            </div>
          </div>
        </div>
      <MortgageCard setMortgageData={setMortgageData} />
      <RentalIncomeCard setRentalData={setRentalData} />
      <RealizationCard setRealizationData={setRealizationData} />
      <ProfitabilityCard
        mortgageData={mortgageData}
        rentalData={rentalData}
        realizationData={realizationData}
      />
    </div>
  );
}

export default LeftColumn;
